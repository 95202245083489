<template>
  <div class="Box-flame Privacy-policy">
    <h3 class="Privacy-policy__ttl">個人情報保護宣言</h3>
    <div class="Privacy-policy__notes">
      <h3 class="Privacy-policy__title">個人情報保護方針</h3>
      <p>
        ライフネット生命保険株式会社（以下「当社」といいます。）は、みなさまの大切な個人情報を適切に取り扱うことが企業としての重要な社会的責任であると認識し、個人情報の保護に係る方針を以下のとおり定め、役員・社員・派遣社員等の全員が、個人情報保護方針に基づき、個人情報の保護に万全をつくしてまいります。
      </p>
      <dl>
        <dt class="Privacy-policy__thema">
          第１．個人情報の取得、利用および提供
        </dt>
        <dd class="Privacy-policy__detail">
          <div class="Privacy-policy__paragraph">
            当社は、個人情報について利用目的を特定し、業務上必要な利用目的の範囲内で、適法かつ適正に取得、利用および提供を行います。
          </div>
        </dd>
        <dt class="Privacy-policy__thema">第２．法令・規範の遵守</dt>
        <dd class="Privacy-policy__detail">
          <div class="Privacy-policy__paragraph">
            当社は、個人情報の取扱いに関する法令、国が定める指針、その他の規範を遵守し、個人情報の適切な保護に努めます。
          </div>
        </dd>
        <dt class="Privacy-policy__thema">第３．個人情報の適切な管理</dt>
        <dd class="Privacy-policy__detail">
          <div class="Privacy-policy__paragraph">
            当社は、個人情報を正確かつ最新の内容に保つよう努めるとともに、合理的な安全対策を講じて、個人情報への不正アクセス、漏えい、滅失、毀損等の防止および是正に努めます。
          </div>
        </dd>
        <dt class="Privacy-policy__thema">第４．継続的改善</dt>
        <dd class="Privacy-policy__detail">
          <div class="Privacy-policy__paragraph">
            当社は、個人情報を保護するための体制（個人情報保護マネジメントシステム）を確立し、実施・維持し、必要に応じて見直し、継続的な改善に努めます。
          </div>
        </dd>
        <dt class="Privacy-policy__thema">第５．お問い合せへの対応</dt>
        <dd class="Privacy-policy__detail">
          <div class="Privacy-policy__paragraph">
            当社は、個人情報の取扱いについて、苦情または相談等のお問い合せがあった場合は適正に対応します。
          </div>
        </dd>
        <dt class="Privacy-policy__thema">第６．当社の住所・代表者の氏名</dt>
        <dd class="Privacy-policy__detail">
          <div class="Privacy-policy__paragraph">
            当社の住所、代表者の氏名等については、以下URLの「会社概要」からご確認ください。<br />
            <a href="https://www.lifenet-seimei.co.jp/profile/outline/"
              >https://www.lifenet-seimei.co.jp/profile/outline/</a
            >
          </div>
        </dd>
        <dt class="Privacy-policy__thema">第７．お問い合わせ窓口</dt>
        <dd class="Privacy-policy__detail">
          <div class="Privacy-policy__paragraph">
            当社における「個人情報保護方針」に関するご質問については以下の窓口にご連絡ください。<br />
            住所<br />
            <p class="Privacy-policy__list-item-content">
              〒102-0084 <br />
              東京都千代田区二番町5番地25 二番町センタービル <br />
              ライフネット生命保険株式会社 コンタクトセンター
            </p>
            電話番号<br />
            <p class="Privacy-policy__list-item-content">
              <a href="tel:0120205566">0120-205566</a> <br />
            </p>
            受付時間<br />
            <p class="Privacy-policy__list-item-content">
              平日9時～20時、土曜9時～18時（日・祝日・年末年始除く）
            </p>
            担当 <br />
            <p class="Privacy-policy__list-item-content">
              リスク管理部担当執行役員 <br />
              ※お電話の内容はご質問やご要望等を正確に把握するため、通話内容を録音しておりますので、あらかじめご了承ください。
            </p>
          </div>
        </dd>
      </dl>
      <table class="Privacy-policy__revision-table">
        <tbody>
          <tr>
            <td class="Privacy-policy__revision-title">制定</td>
            <td>2008年4月23日</td>
          </tr>
          <tr>
            <td class="Privacy-policy__revision-title">施行</td>
            <td>営業開始日</td>
          </tr>
          <tr>
            <td class="Privacy-policy__revision-title">最終改定日</td>
            <td>2024年5月31日</td>
          </tr>
        </tbody>
      </table>
      <br />
      <h3 class="Privacy-policy__title">
        お客さまの個人情報の取扱い等について
      </h3>
      <dl>
        <dt class="Privacy-policy__thema">第１．個人情報の取扱い</dt>
        <ol class="Privacy-policy__sublist-custom">
          <li>
            <div>
              <span class="Privacy-policy__sublist-counter">１．</span>
              <span class="Privacy-policy__sublist-content"
                >個人情報の取扱事業者の名称</span
              >
              <p class="Privacy-policy__list-item-content">
                取扱事業者の名称：ライフネット生命保険株式会社（以下「当社」といいます。）<br />
                住所、代表者の氏名等については、以下URLの「会社概要」からご確認ください。<br />
                <a href="https://www.lifenet-seimei.co.jp/profile/outline/"
                  >https://www.lifenet-seimei.co.jp/profile/outline/</a
                >
              </p>
            </div>
          </li>
        </ol>
        <ol class="Privacy-policy__sublist-custom">
          <li>
            <div>
              <span class="Privacy-policy__sublist-counter">２．</span>
              <span class="Privacy-policy__sublist-content"
                >個人情報保護管理者の職名および連絡先</span
              >
              <p class="Privacy-policy__list-item-content">
                個人情報保護管理者：リスク管理部担当執行役員<br />
                電話番号：<a href="tel:0120205566">0120-205566</a>
              </p>
            </div>
          </li>
        </ol>
        <ol class="Privacy-policy__sublist-custom">
          <li>
            <div>
              <span class="Privacy-policy__sublist-counter">３．</span>
              <span class="Privacy-policy__sublist-content"
                >取得・管理・利用するお客さまの個人情報の種類</span
              >
              <p class="Privacy-policy__list-item-content">
                当社が取得・管理・利用するお客さまの個人情報は、氏名、住所、生年月日、性別、健康状態、職業、電子メールアドレス、端末識別子等です。
              </p>
            </div>
          </li>
        </ol>
        <ol class="Privacy-policy__sublist-custom">
          <li>
            <div>
              <span class="Privacy-policy__sublist-counter">４．</span>
              <span class="Privacy-policy__sublist-content"
                >個人情報の取得方法</span
              >
              <p class="Privacy-policy__list-item-content">
                当社は、当社のウェブサイト上の画面、電話、契約申込書類等を通じて、個人情報保護法、保険業法その他の法令等に照らし適正な方法により個人情報を取得いたします。
              </p>
            </div>
          </li>
        </ol>
        <ol class="Privacy-policy__sublist-custom">
          <li>
            <div>
              <span class="Privacy-policy__sublist-counter">５．</span>
              <span class="Privacy-policy__sublist-content"
                >個人情報の利用目的</span
              >
              <p class="Privacy-policy__list-item-content">
                当社は、お客さまに関する個人情報を、必要に応じて、以下の利用目的の達成に必要な範囲でのみ適正に利用し、それ以外の目的には利用しません。<br />
              </p>
              <ol class="Privacy-policy__sublist1">
                <li class="Privacy-policy__sublist1-li">
                  本人より直接取得する個人情報の利用目的（開示等の対象となる個人情報）<br />
                  <table class="Privacy-policy__table">
                    <thead>
                      <tr>
                        <th class="Privacy-policy__table-head1">
                          個人情報の種別
                        </th>
                        <th class="Privacy-policy__table-head2">利用目的</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>顧客情報</td>
                        <td>
                          <ol class="Privacy-policy__sublist2">
                            <li>
                              各種保険契約の引受、契約の維持管理、保険金・給付金等の支払
                            </li>
                            <li>
                              当社または関連会社・提携会社を含む各種商品・サービスの案内・提供（※）
                            </li>
                            <li>
                              当社業務に関する情報提供、商品・サービスの充実（※）
                            </li>
                            <li>
                              再保険契約の締結、再保険契約に基づく通知および再保険金の請求
                            </li>
                            <li>その他保険に関連・付随する業務（※）</li>
                          </ol>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
                <ol class="Privacy-policy__bracket-starlist">
                  <li class="Privacy-policy__bracket-starlist-li">
                    お客さまの取引履歴やウェブサイトの閲覧履歴、グループ会社等から取得した情報等を分析・集計して、お客さまのニーズにあった各種商品・サービスに関する情報・広告等の配信・表示、それらに関する効果測定等をすることを含みます。
                  </li>
                </ol>
                <li class="Privacy-policy__sublist1-li">
                  取引先等から間接的に取得する個人情報の利用目的（開示等の対象外となる個人情報）<br />
                  <table class="Privacy-policy__table">
                    <thead>
                      <tr>
                        <th class="Privacy-policy__table-head1">
                          個人情報の種別
                        </th>
                        <th class="Privacy-policy__table-head2">利用目的</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>委託先、提携会社等から提供された顧客情報</td>
                        <td>
                          <ol class="Privacy-policy__sublist2">
                            <li>
                              各種保険契約の引受、契約の維持管理、保険金・給付金等の支払
                            </li>
                            <li>
                              当社または関連会社・提携会社を含む各種商品・サービスの案内・提供
                            </li>
                            <li>
                              当社業務に関する情報提供、商品・サービスの充実
                            </li>
                          </ol>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
              </ol>
            </div>
          </li>
        </ol>
        <ol class="Privacy-policy__sublist-custom">
          <li>
            <div>
              <span class="Privacy-policy__sublist-counter">６．</span>
              <span class="Privacy-policy__sublist-content"
                >個人情報の提供</span
              >
              <p class="Privacy-policy__list-item-content">
                当社は、「３．取得・管理・利用するお客さまの個人情報の種類」で列挙したお客さまの個人情報を、以下の場合において、委託先、提携会社、再保険会社等の当社以外の者に電子的手段または郵送等によって提供することがあります。また、お客さまの個人情報を当社以外の者に提供する場合は、事前に秘密保持契約を締結した上で個人情報を提供します。
              </p>
              <ol class="Privacy-policy__sublist1">
                <li class="Privacy-policy__sublist1-li">
                  あらかじめお客さまの同意がある場合
                </li>
                <li class="Privacy-policy__sublist1-li">
                  法令により必要とされる場合または提供が認められている場合
                </li>
                <li class="Privacy-policy__sublist1-li">
                  適切な安全管理をしたうえで、当社の業務上必要な範囲で、個人情報を当社の委託先に提供する場合
                </li>
                <li class="Privacy-policy__sublist1-li">
                  人の命、身体または財産の保護のために必要とされる場合
                </li>
                <li class="Privacy-policy__sublist1-li">
                  公共の利益のために必要とされる場合
                </li>
                <li class="Privacy-policy__sublist1-li">
                  個人情報保護法に従って個人情報の共同利用を行う場合
                </li>
                <li class="Privacy-policy__sublist1-li">
                  再保険契約の締結（※）、再保険契約に基づく通知および再保険金の請求その他必要な範囲内で、再保険会社に提供する場合<br />
                  <ol class="Privacy-policy__bracket-starlist">
                    <li>
                      再保険契約は、引受リスクの判断や適切な分散を主な目的としています。再保険会社に提供する情報には、再保険の対象となる保険契約の特定に必要な個人情報のほか、被保険者の氏名、生年月日、性別、保健医療などの個人情報が含まれます。
                    </li>
                  </ol>
                </li>
                <li class="Privacy-policy__sublist1-li">
                  当社の商品・サービスの案内等を行うために、お客さまに関する情報（電話番号またはメールアドレス）を、不可逆の値へ変換する（ハッシュ化）等の適切な方法により変換したうえで、第三者である広告配信事業者に提供する場合
                </li>
              </ol>
            </div>
          </li>
        </ol>
        <ol class="Privacy-policy__sublist-custom">
          <li>
            <div>
              <span class="Privacy-policy__sublist-counter">７．</span>
              <span class="Privacy-policy__sublist-content"
                >外国にある第三者への個人情報の提供</span
              >
              <ol class="Privacy-policy__sublist1">
                <li class="Privacy-policy__sublist1-li">
                  当社は、個人データの取扱いについて個人情報保護法における所定の規定により個人情報取扱事業者が講ずべき措置に相当する措置（以下「相当措置」という。）を継続的に講ずるために必要なものとして法令に定める基準に適合する体制を整備していることを根拠として外国にある再保険会社等の第三者に個人データを提供することがあります。
                </li>
                <li class="Privacy-policy__sublist1-li">
                  当社は、相当措置の実施状況ならびに当該相当措置の実施に影響を及ぼすおそれのある当該外国の制度の有無およびその内容を定期的に確認することとしております。また、当該第三者による相当措置の実施に支障が生じたときは、必要かつ適切な措置を講ずるとともに、当該相当措置の継続的な実施の確保が困難となったときは、個人情報の当該第三者への提供を停止いたします。
                </li>
                <li class="Privacy-policy__sublist1-li">
                  当社は、お客さまの同意を得た上で、個人情報を外国にある第三者に提供することがあります。
                </li>
              </ol>
            </div>
          </li>
        </ol>
        <ol class="Privacy-policy__sublist-custom">
          <li>
            <div>
              <span class="Privacy-policy__sublist-counter">８．</span>
              <span class="Privacy-policy__sublist-content"
                >個人情報の共同利用</span
              >
              <p class="Privacy-policy__list-item-content">
                当社は、お客さまに関する個人情報について、以下の場合において、必要な範囲で共同利用を行うことがあります。
              </p>
              <ol class="Privacy-policy__sublist1">
                <li class="Privacy-policy__sublist1-li">
                  各生命保険会社等との共同利用<br />
                  当社は、生命保険制度が健全に運営され、保険金および入院給付金等のお支払いが正しく確実に行なわれるよう、「契約内容登録制度」、「契約内容照会制度」、および「支払査定時照会制度」にもとづき、当社の保険契約等に関する所定の情報を一般社団法人生命保険協会、一般社団法人生命保険協会加盟の他の各生命保険会社（「各生命保険会社等」という。）および全国共済農業協同組合連合会と共同して利用しております。詳細は以下のURLからご確認ください。<br />
                  <a
                    href="https://www.lifenet-seimei.co.jp/policy/privacy/reference/"
                    >https://www.lifenet-seimei.co.jp/policy/privacy/reference/</a
                  >
                </li>
                <li class="Privacy-policy__sublist1-li">
                  子会社との共同利用<br />
                  <ol class="Privacy-policy__sublist2">
                    <li>
                      共同利用者の範囲<br />
                      当社およびライフネットみらい株式会社
                    </li>
                    <li>
                      共同して利用される個人情報の項目<br />
                      氏名、住所、生年月日、性別、健康状態、職業、電子メールアドレス、端末識別子
                    </li>
                    <li>
                      共同利用の目的<br />
                      当社または関連会社・提携会社を含む各種商品・サービスの案内・提供
                    </li>
                    <li>
                      共同利用の管理責任者<br />
                      ライフネット生命保険株式会社（当社）<br />
                      当社の住所、代表者の氏名等については、以下URLの「会社概要」からご確認ください。<br />
                      <a
                        href="https://www.lifenet-seimei.co.jp/profile/outline/"
                        >https://www.lifenet-seimei.co.jp/profile/outline/</a
                      >
                    </li>
                    <li>
                      取得方法<br />
                      電子データによる取得
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
          </li>
        </ol>
        <ol class="Privacy-policy__sublist-custom">
          <li>
            <div>
              <span class="Privacy-policy__sublist-counter">９．</span>
              <span class="Privacy-policy__sublist-content"
                >要配慮個人情報、機微（センシティブ）情報の取扱い</span
              >
              <ol class="Privacy-policy__sublist1">
                <li class="Privacy-policy__sublist1-li">
                  当社は、要配慮個人情報ならびに労働組合への加盟、門地、本籍地、保健医療および性生活に関する個人情報（以下「機微（センシティブ）情報」といいます。）については、個人情報保護法およびその他関連する法令・ガイドラインに規定する場合を除き、あらかじめ本人の同意を得ずに取得、利用または第三者提供を行いません。
                </li>
                <li class="Privacy-policy__sublist1-li">
                  当社は、要配慮個人情報および機微（センシティブ）情報を、保険業の適切な運営を確保する必要性から、業務上必要な範囲内で、各種保険契約の引受け、継続・維持管理、保険金・給付金等の支払、保険商品の開発および維持研究・統計等のために利用いたします。
                </li>
              </ol>
            </div>
          </li>
        </ol>
        <ol class="Privacy-policy__sublist-custom">
          <li>
            <div>
              <span class="Privacy-policy__sublist-counter">１０．</span>
              <span class="Privacy-policy__sublist-content"
                >個人情報を提供しなかった場合に生じる結果</span
              >
              <p class="Privacy-policy__list-item-content">
                当社への個人情報の提供は本人の任意であり、提供を断ることができます。ただし、上記個人情報の提供がない場合は、業務処理に支障が発生するために本人に不利益が生じる場合があることをあらかじめご承知おきください。
              </p>
            </div>
          </li>
        </ol>
        <ol class="Privacy-policy__sublist-custom">
          <li>
            <div>
              <span class="Privacy-policy__sublist-counter">１１．</span>
              <span class="Privacy-policy__sublist-content"
                >本人が容易に認識できない方法による個人情報の取得
              </span>
              <p class="Privacy-policy__list-item-content">
                当社のウェブサイトでは、お客さまによるウェブサイトのご利用をより充実したものとするために、Cookie（クッキー）およびWebビーコンを取得しています。<br />
                詳細は以下のURLからご確認ください。<br />
                <a href="https://www.lifenet-seimei.co.jp/policy/cookie/"
                  >https://www.lifenet-seimei.co.jp/policy/cookie/</a
                >
              </p>
            </div>
          </li>
        </ol>
        <ol class="Privacy-policy__sublist-custom">
          <li>
            <div>
              <span class="Privacy-policy__sublist-counter">１２．</span>
              <span class="Privacy-policy__sublist-content"
                >保有個人データの開示・訂正・利用停止・消去等</span
              >
              <p class="Privacy-policy__list-item-content">
                保有個人データに関して、本人の開示等（利用目的の通知、開示、内容の訂正、追加または削除、利用の停止、消去および第三者への提供の停止）ならびに第三者提供記録の開示の請求があった場合は、請求者が本人であることを確認させていただいたうえで、個人情報保護法の規定に基づき、それらの対応を行います。<br />
                保有個人データの開示等の請求は、以下の窓口にご連絡ください。<br />
                住所
              </p>
              <p class="Privacy-policy__list-item-content-double">
                〒102-0084<br />
                東京都千代田区二番町5番地25 二番町センタービル<br />
                ライフネット生命保険株式会社 コンタクトセンター
              </p>
              <p class="Privacy-policy__list-item-content">電話番号</p>
              <p class="Privacy-policy__list-item-content-double">
                <a href="tel:0120205566">0120-205566</a><br />
              </p>
              <p class="Privacy-policy__list-item-content">受付時間</p>
              <p class="Privacy-policy__list-item-content-double">
                平日9時～20時、土曜9時～18時（日・祝日・年末年始除く）
              </p>
              <br />
              <p class="Privacy-policy__list-item-content">
                保有個人データに関する開示等請求については、以下のURLからご確認ください。<br />
                <a
                  href="https://www.lifenet-seimei.co.jp/policy/privacy/disclosure/"
                  >https://www.lifenet-seimei.co.jp/policy/privacy/disclosure/</a
                >
              </p>
            </div>
          </li>
        </ol>
        <ol class="Privacy-policy__sublist-custom">
          <li>
            <div>
              <span class="Privacy-policy__sublist-counter">１３．</span>
              <span class="Privacy-policy__sublist-content">安全管理措置</span>
              <p class="Privacy-policy__list-item-content">
                当社は、個人データについて、漏えい、滅失または毀損の防止等、その管理のために必要かつ適切な安全管理措置を講じます。また、個人データを取り扱う従業者や委託先（再委託先等を含みます。）に対して、必要かつ適切な監督を行います。個人データの安全管理措置に関しては、社内規程において具体的に定めておりますが、その主な内容は以下のとおりです。
              </p>
              <ol class="Privacy-policy__sublist1">
                <li class="Privacy-policy__sublist1-li">
                  「お客さまの個人情報の取扱い等について」の策定<br />
                  個人データの適正な取扱いの確保のため、関係法令・ガイドライン等の遵守、質問および苦情処理の窓口等について「お客さまの個人情報の取扱い等について」を策定しています。<br />
                </li>
                <li class="Privacy-policy__sublist1-li">
                  個人データの取扱いに係る規律の整備<br />
                  取得、利用、保存、提供、削除・廃棄等の段階ごとに、取扱方法、責任者・担当者およびその任務等について社内規程を策定しています。
                </li>
                <li class="Privacy-policy__sublist1-li">
                  組織的安全管理措置<br />
                  <ol class="Privacy-policy__sublist2">
                    <li>
                      個人データの取扱いに関する個人情報統括管理責任者を設置するとともに、個人データを取り扱う従業者および当該従業者が取り扱う個人データの範囲を明確化し、個人情報保護法や社内規程に違反している事実または兆候を把握した場合の個人情報統括管理責任者への報告連絡体制を整備しています。
                    </li>
                    <li>
                      個人データの取扱状況について、定期的に自己点検を実施するとともに、監査を実施しています。
                    </li>
                  </ol>
                </li>
                <li class="Privacy-policy__sublist1-li">
                  人的安全管理措置<br />
                  <ol class="Privacy-policy__sublist2">
                    <li>
                      個人データの取扱いに関する留意事項について、従業者に定期的な研修を実施しています。
                    </li>
                    <li>
                      個人データについての秘密保持に関する事項を就業規則に記載しています。
                    </li>
                  </ol>
                </li>
                <li class="Privacy-policy__sublist1-li">
                  物理的安全管理措置<br />
                  <ol class="Privacy-policy__sublist2">
                    <li>
                      個人データを取り扱う区域において、従業者の入退室管理および持ち込む機器等の制限を行うとともに、権限を有しない者による個人データの閲覧を防止する措置を実施しています。
                    </li>
                    <li>
                      個人データを取り扱う機器、電子媒体および書類等の盗難または紛失等を防止するための措置を講じるとともに、事業所内の移動を含め、当該機器、電子媒体等を持ち運ぶ場合、容易に個人データが判明しないよう措置を実施しています。
                    </li>
                  </ol>
                </li>
                <li class="Privacy-policy__sublist1-li">
                  技術的安全管理措置<br />
                  <ol class="Privacy-policy__sublist2">
                    <li>
                      アクセス制御を実施して、担当者および取り扱う個人情報データベース等の範囲を限定しています。
                    </li>
                    <li>
                      個人データを取り扱う情報システムを外部からの不正アクセスまたは不正ソフトウェアから保護する仕組みを導入しています。
                    </li>
                  </ol>
                </li>
                <li class="Privacy-policy__sublist1-li">
                  外国における個人情報の取扱い<br />
                  当社は、個人情報を外国に保存することがあります。その場合、保存している国における個人情報の保護に関する制度を把握した上で安全管理措置を実施しています。
                </li>
              </ol>
            </div>
          </li>
        </ol>
        <dt class="Privacy-policy__thema">第２．特定個人情報の取扱い</dt>
        <ol class="Privacy-policy__sublist-custom">
          <li>
            <div>
              <span class="Privacy-policy__sublist-counter">１．</span>
              <span class="Privacy-policy__sublist-content"
                >特定個人情報の取扱い</span
              >
              <p class="Privacy-policy__list-item-content">
                当社は、お客さまに対し、「行政手続における特定の個人を識別するための番号の利用等に関する法律」（マイナンバー法）に基づき、「保険取引に関する支払調書作成事務」に利用するために個人番号の提供を依頼いたします。
              </p>
            </div>
            <div>
              <span class="Privacy-policy__sublist-counter">２．</span>
              <span class="Privacy-policy__sublist-content"
                >特定個人情報の管理</span
              >
              <p class="Privacy-policy__list-item-content">
                当社は、関係法令等を遵守し、提供していただいたお客さまの個人番号および特定個人情報の紛失、漏えい、毀損等の危険に対して必要な対策を講じています。
              </p>
            </div>
          </li>
        </ol>
        <dt class="Privacy-policy__thema">
          第3．お問い合わせ窓口<br />当社におけるお客さまの個人情報の取扱いに関するご質問や苦情については以下の窓口にご連絡ください。
        </dt>
        <div>
          <p class="Privacy-policy__list-item-content">住所</p>
          <p class="Privacy-policy__list-item-content-double">
            〒102-0084<br />
            東京都千代田区二番町5番地25 二番町センタービル<br />
            ライフネット生命保険株式会社 コンタクトセンター
          </p>
          <p class="Privacy-policy__list-item-content">電話番号</p>
          <p class="Privacy-policy__list-item-content-double">
            <a href="tel:0120205566">0120-205566</a>
          </p>
          <p class="Privacy-policy__list-item-content">受付時間</p>
          <p class="Privacy-policy__list-item-content-double">
            平日9時～20時、土曜9時～18時（日・祝日・年末年始除く）<br />
          </p>
          <p class="Privacy-policy__list-item-content">担当</p>
          <p class="Privacy-policy__list-item-content-double">
            リスク管理部担当執行役員
          </p>
          <ol class="Privacy-policy__starlist">
            <li>
              お電話の内容はお客さまからのご質問やご要望などを正確に把握するため、通話内容を録音しておりますので、あらかじめご了承ください。
            </li>
          </ol>
        </div>
        <dt class="Privacy-policy__thema">
          第４．当社が対象事業者となっている認定個人情報保護団体について<br />
          当社は、認定個人情報保護団体である一般社団法人生命保険協会の対象事業者です。同協会では、対象事業者の個人情報の取扱いに関する苦情・相談を受け付けております。
        </dt>
        <div>
          <p class="Privacy-policy__list-item-content">
            ＜認定個人情報保護団体のお問い合わせ先＞<br />
            （一社）生命保険協会 生命保険相談所：〒100-0005 千代田区丸の内3-4-1
            新国際ビル3階<br />
            受付時間：午前9時から午後5時（土・日・祝日などの生命保険協会休業日を除く）<br />
            電話番号：<a href="tel:0332862648">03-3286-2648</a><br />
            ホームページ：<a href="https://www.seiho.or.jp/"
              >https://www.seiho.or.jp/</a
            >
          </p>
        </div>
        <table class="Privacy-policy__revision-table">
          <tbody>
            <tr>
              <td class="Privacy-policy__revision-title">制定</td>
              <td>2008年4月23日</td>
            </tr>
            <tr>
              <td class="Privacy-policy__revision-title">施行</td>
              <td>営業開始日</td>
            </tr>
            <tr>
              <td class="Privacy-policy__revision-title">最終改定日</td>
              <td>2024年5月31日</td>
            </tr>
          </tbody>
        </table>
      </dl>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import FormComponentSimple from "@/components/atoms/FormComponentSimple.vue";

export default defineComponent({
  name: "PolicyProtectionMolecules",
});
</script>
<style scoped>
.Privacy-policy__revision-table {
  text-align: right;
  display: flex;
  justify-content: right;
}
.Privacy-policy__revision-title {
  padding-right: 10px;
}
.Privacy-policy__title {
  margin: 10px 0 5px;
  font-size: 24px;
}
.Privacy-policy__table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
.Privacy-policy__table th {
  white-space: nowrap;
  padding: 10px;
  border: solid 1px #778ca3;
}
.Privacy-policy__table-head1 {
  width: 30%;
  border-collapse: collapse;
  border-spacing: 0;
}
.Privacy-policy__table-head2 {
  width: 70%;
  border-collapse: collapse;
  border-spacing: 0;
}
.Privacy-policy__table td {
  padding: 10px;
  border: solid 1px #778ca3;
}
.Box-flame {
  overflow: hidden;
  border: 1px solid #bfbfbf;
  border-radius: 8px;
  background: #fefffd;
}
.Box-flame + .Box-flame {
  margin-top: 20px;
}
.Form-wrap .Box-flame {
  border-color: #80b735;
  box-shadow: none;
}

button,
input {
  overflow: visible;
}
a {
  color: #0044cc;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.Privacy-policy__ttl {
  display: flex;
  height: 56px;
  border-bottom: 1px solid #80b735;
  background: #f4faeb;
  color: #2c4318;
  font-size: 2rem;
  font-weight: normal;
  align-items: center;
  justify-content: center;
}

.Privacy-policy__thema {
  font-weight: normal;
  margin-top: 1em;
}
h1,
h2,
h3,
h4,
h5,
h6,
dl,
dt,
dd {
  margin: 0;
  font-size: 100%;
}
.Privacy-policy__sublist-custom {
  list-style-type: none;
  margin-left: 20px;
}
dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}
.Privacy-policy__sublist-counter {
  margin-left: -32px;
}
.Privacy-policy__sublist-content {
  margin-left: -4px;
}
.Privacy-policy__list-item-content {
  padding-left: 16px;
}
.Privacy-policy__list-item-content-double {
  padding-left: 32px;
}
.Privacy-policy__sublist1 {
  padding-left: 0;
  list-style-type: none;
  counter-reset: number-list1;
  margin-left: 16px;
}
.Privacy-policy__sublist1 li {
  padding-left: 44px;
}
.Privacy-policy__sublist1 .Privacy-policy__bracket-starlist-li {
  padding-left: 50px;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}
.Privacy-policy__sublist1 .Privacy-policy__sublist1-li::before {
  counter-increment: number-list1;
  content: "（" counter(number-list1) "）";
  margin-left: -44px;
}
.Privacy-policy__bracket-starlist {
  padding-left: 0;
  list-style-type: none;
}
.Privacy-policy__bracket-starlist li::before {
  content: "（※）";
  margin-left: -50px;
}
.Privacy-policy__sublist2 {
  padding-left: 0;
  list-style-type: none;
  counter-reset: number-list2;
}
.Privacy-policy__sublist2 li {
  padding-left: 18px;
}

.Privacy-policy__sublist2 li::before {
  counter-increment: number-list2;
  content: counter(number-list2);
  margin-left: -21.5px;
  border-radius: 12px;
  border-width: 1px;
  border-style: solid;
  font-size: 11px;
  padding-left: 4px;
  padding-right: 3px;
  bottom: 1px;
  position: relative;
}
.Privacy-policy__paragraph {
  margin-top: 1em;
  margin-left: 32px;
}
.Privacy-policy__paragraph:first-child {
  margin-top: 0;
}

.u-txtr {
  text-align: right;
}
.Privacy-policy__list-item-note {
  padding-left: 40px;
}
.Privacy-policy__starlist {
  padding-left: 0;
  list-style-type: none;
  counter-reset: star-list;
}
.Privacy-policy__starlist li {
  padding-left: 18px;
}
.Privacy-policy__starlist li::before {
  content: "※";
  margin-left: -18px;
}
@media screen and (min-width: 501px) {
  .Privacy-policy__notes {
    position: relative;
    overflow-y: scroll;
    height: 220px;
    padding: 25px 40px;
  }
}
@media screen and (max-width: 501px) {
  .Privacy-policy__table th {
    white-space: normal;
    padding: 10px;
    border: solid 1px #778ca3;
  }
  .Privacy-policy__notes {
    position: relative;
    overflow-y: scroll;
    height: 220px;
    padding: 25px 10px;
    letter-spacing: normal;
  }

  .Privacy-policy__ttl {
    font-weight: bold;
  }
}
</style>
