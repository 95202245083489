<template>
  <!-- 資料請求画面の入力フォーム～「入力内容の確認へ」 -->
  <div class="l-base">
    <ErrorMessageAreaMolecules
      v-if="!validationResultFlg"
      :errorList="validationMessages"
    />
    <div class="Form-wrap">
      <div class="Box-flame">
        <table class="Form-tbl">
          <tbody>
            <tr class="Form-tbl__tr Form-tbl__tr-first">
              <th id="namearea" class="Form-tbl__th bgg2">
                <div class="Form-name">
                  お名前<span class="Ico-must">必須</span>
                </div>
              </th>
              <td id="input_name" class="Form-tbl__td kanji">
                <div class="Date-box">
                  <NameInputFormMolecules
                    type="text"
                    placeHolder="来風"
                    formTitle="姓"
                    :maxLength="14"
                    :required="true"
                    v-model="inputParam.familyName"
                  />
                  <NameInputFormMolecules
                    type="text"
                    placeHolder="大輔"
                    formTitle="名"
                    :maxLength="9"
                    :required="true"
                    v-model="inputParam.firstName"
                  />
                </div>
              </td>
            </tr>
            <tr class="Form-tbl__tr Form-tbl__tr-first">
              <th id="label_name_kana" class="Form-tbl__th bgg2">
                <div class="Form-name">
                  お名前（カタカナ）<span class="Ico-must">必須</span>
                </div>
              </th>
              <td id="input_name_kana" class="Form-tbl__td kana">
                <div class="Date-box">
                  <NameInputFormMolecules
                    type="text"
                    placeHolder="ライフ"
                    formTitle="セイ"
                    :maxLength="30"
                    :required="true"
                    :kanaInput="true"
                    @focusout="familyNameKanaChange()"
                    v-model="inputParam.familyNameKana"
                  />
                  <NameInputFormMolecules
                    type="text"
                    placeHolder="ダイスケ"
                    formTitle="メイ"
                    :maxLength="20"
                    :required="true"
                    :kanaInput="true"
                    @focusout="firstNameKanaChange()"
                    v-model="inputParam.firstNameKana"
                  />
                </div>
              </td>
            </tr>
            <tr class="Form-tbl__tr Form-tbl__tr-first">
              <th id="label_insured_person_gender" class="Form-tbl__th bgg2">
                <div class="Form-name">
                  性別<span class="Ico-must">必須</span>
                </div>
              </th>
              <td id="b04_00" class="Form-tbl__td">
                <GenderMolecules v-model="inputParam.insuredPersonGender" />
              </td>
            </tr>

            <tr class="Form-tbl__tr Form-tbl__tr-first">
              <th id="label_birth_date" class="Form-tbl__th bgg2">
                <div class="Form-name">
                  生年月日<span class="Ico-must">必須</span>
                </div>
              </th>
              <td id="select_birth_date" class="Form-tbl__td">
                <div id="nomal_birth" class="Date-box Date-box--w02">
                  <DateOfBirthMolecules v-model="inputParam.birthArray" />
                  <p v-if="monthLastMessage != ''">{{ monthLastMessage }}</p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!--/.Box-flame-->
      <div class="Box-flame">
        <table class="Form-tbl contact-info-table">
          <tbody>
            <tr class="Form-tbl__tr">
              <th
                id="addarea1"
                rowspan="4"
                class="Form-tbl__th--confirm bgg2 Form-tbl__th--resi"
              >
                ご住所
              </th>
              <th id="addarea2" class="Form-tbl__th Form-tbl__th--col bgg2">
                <div class="Form-name">
                  郵便番号<span class="Ico-must">必須</span>
                </div>
              </th>
              <td id="addarea3" class="Form-tbl__td contents">
                <p>
                  郵便番号を入力後、「郵便番号から住所を検索する」ボタンをクリックしてください
                </p>
                <div class="Date-box Date-box--w03">
                  <input
                    name="zipcode"
                    type="text"
                    maxlength="7"
                    id="a_04_01"
                    v-model="searchZipCode"
                    placeholder="1020084"
                    class="Input Input--zip"
                    @focusout="zipcodeNumberChange()"
                    @keydown.enter="getAddress()"
                  />
                  <button
                    type="button"
                    id="automatic"
                    @click="getAddress()"
                    class="Btn Btn--type01"
                  >
                    郵便番号から住所を検索する
                  </button>
                </div>
                <p class="Address-holder__sub">
                  <a
                    href="http://www.post.japanpost.jp/zipcode/index.html"
                    class="Link-arw"
                    target="_blank"
                    >住所から郵便番号を調べる場合はこちら</a
                  >
                </p>
              </td>
            </tr>
            <tr class="Form-tbl__tr">
              <th
                id="label_city_name"
                class="Form-tbl__th Form-tbl__th--col bgg2"
              >
                <div class="Form-name">
                  都道府県<br />市区町村<span class="Ico-must">必須</span>
                </div>
              </th>
              <td id="input_city_name" class="Form-tbl__td">
                <span id="adr_top" style="display: none">
                  {{ inputParam.stateCityName }}
                  <br />
                </span>
                <div id="a_04_02_text" class="Date-box Date-box--w03">
                  <input
                    disabled="true"
                    name="city_name"
                    type="text"
                    placeholder="郵便番号から検索してください"
                    id="a_04_02_b"
                    class="Input"
                    v-model="inputParam.cityName"
                    maxlength="11"
                  />
                </div>
                <div
                  id="a_04_02_select"
                  class="Date-box Date-box--w03"
                  style="display: none"
                >
                  <div class="Select-wrap">
                    <select
                      name="addresscode"
                      id="a_04_02_a"
                      class="Custom-select errPoint"
                      v-model="inputParam.cityName"
                    >
                      <option value="">お選びください →</option>
                      <option
                        v-for="select in selectList"
                        :value="select.addressdisp"
                        :key="select.addresscode"
                      >
                        {{ select.addressdisp }}
                      </option>
                    </select>
                  </div>
                </div>
                <p class="Ex-txt Ex-txt--min">（例：東京都千代田区二番町）</p>
              </td>
            </tr>
            <tr class="Form-tbl__tr">
              <th
                id="label_block_number"
                class="Form-tbl__th Form-tbl__th--col bgg2"
              >
                <div class="Form-name">
                  番地<span class="Ico-must">必須</span>
                </div>
              </th>
              <td id="input_block_number" class="Form-tbl__td">
                <p class="Address-holder__sub">番地以降を入力してください</p>
                <div class="Date-box Date-box--w03">
                  <LongInputMolecules
                    type="text"
                    formTitle="番地"
                    :max-length="29"
                    :required="true"
                    v-model="inputParam.blockNumber"
                  />
                </div>
                <p class="Ex-txt Ex-txt--min">（例：5番地25, 5-25）</p>
              </td>
            </tr>
            <tr class="Form-tbl__tr">
              <th
                id="label_building_name"
                class="Form-tbl__th Form-tbl__th--col bgg2"
              >
                <div class="Form-name-pc">マンション名<br />部屋番号</div>
                <div class="Form-name-sp">マンション名・部屋番号</div>
              </th>
              <td id="input_building_name" class="Form-tbl__td">
                <div class="Date-box Date-box--w03">
                  <input
                    name="building_name"
                    type="text"
                    maxlength="29"
                    id="a_04_04"
                    class="Input"
                    v-model="inputParam.buildingName"
                  />
                </div>
                <p class="Ex-txt Ex-txt--min">（例：二番町〇〇ビル）</p>
              </td>
            </tr>
            <tr class="Form-tbl__tr">
              <th colspan="2" id="label_phone" class="Form-tbl__th bgg2">
                <div class="Form-name">
                  電話番号<span class="Ico-must">必須</span>
                </div>
              </th>
              <td id="input_primary_phone" class="Form-tbl__td">
                <div class="Date-box">
                  <p>
                    携帯電話番号もしくは自宅電話番号のどちらか一方を必ず入力してください
                  </p>
                  <LongInputMolecules
                    type="text"
                    placeHolder="08023456789"
                    formTitle="電話番号"
                    :maxLength="11"
                    :required="true"
                    @focusout="primaryPhoneNumberChange()"
                    v-model="inputParam.primaryPhone"
                  />
                  <p class="Ex-txt">（例：08023456789，0312345678）</p>
                </div>
              </td>
            </tr>
            <tr class="Form-tbl__tr">
              <th colspan="2" id="label_email" class="Form-tbl__th bgg2">
                <div class="Form-name">
                  メールアドレス<span class="Ico-must">必須</span>
                </div>
              </th>
              <td id="input_email" class="Form-tbl__td bgw">
                <div class="Date-box">
                  <LongInputMolecules
                    type="text"
                    v-model="inputParam.email"
                    formTitle="メールアドレス"
                    :email="true"
                    :maxLength="80"
                    :required="true"
                  />
                  <p class="Ex-txt">（例：lifenet@lifenet-seimei.co.jp）</p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p class="Block-head__txt">
        保険選びに役立つ情報をご提供するため、よろしければ以下もご入力ください。
      </p>
      <!--/.Box-flame-->
      <div class="Box-flame">
        <table class="Form-tbl">
          <tbody>
            <tr class="Form-tbl__tr">
              <th id="label_marital_status" class="Form-tbl__th bgg2">
                <div class="Form-name">家族構成</div>
              </th>
              <td id="b11_00" class="Form-tbl__td">
                <MaritalStatusMolecules v-model="inputParam.maritalStatus" />
              </td>
            </tr>
            <tr class="Form-tbl__tr">
              <th id="label_child_number" class="Form-tbl__th bgg2">
                <div class="Form-name">18歳未満のお子さま</div>
              </th>
              <td id="b06_00" class="Form-tbl__td">
                <ChildPresenceMolecules v-model="inputParam.childNumber" />
              </td>
            </tr>
            <tr class="Form-tbl__tr">
              <th id="label_working_style" class="Form-tbl__th bgg2">
                <div class="Form-name">就業状況</div>
              </th>
              <td id="b07_00" class="Form-tbl__td">
                <WorkingStyleMolecules
                  v-model="inputParam.workingStyle"
                  :genderCodeValue="inputParam.insuredPersonGender"
                />
              </td>
            </tr>
            <tr class="Form-tbl__tr">
              <th id="label_concern" class="Form-tbl__th bgg2">
                <div class="Form-name">気になる項目（複数選択可）</div>
              </th>
              <td id="input_concern" class="Form-tbl__td">
                <ConcernCheckboxMolecules
                  v-model="inputParam.concern"
                  :reEnterStatus="reEnterStatus"
                />
              </td>
            </tr>
            <tr class="Form-tbl__tr">
              <th id="label_mullTriggeredCue" class="Form-tbl__th">
                <div class="Form-name">
                  今回の保険検討のきっかけ<br />（複数選択可）
                </div>
              </th>
              <td id="input_mullTriggeredCue" class="Form-tbl__td">
                <MullTriggeredCueCheckboxMolecules
                  v-model="inputParam.mullTriggeredCue"
                  :reEnterStatus="reEnterStatus"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!--/.Box-flame-->
      <div class="Box-flame">
        <table class="Form-tbl">
          <tbody>
            <tr class="Form-tbl__tr" id="tr_wish_email">
              <th id="label_wish_email" class="Form-tbl__th bgg2">
                <div class="Form-name">お役立ち情報を受け取る</div>
              </th>
              <td
                id="input_wish_email"
                class="Form-tbl__td Form-tbl__td--inside"
              >
                <WishMailCheckboxMolecules
                  v-model="inputParam.wishEmail"
                  :reEnterStatus="reEnterStatus"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!--/.Box-flame-->
      <PolicyProtectionMolecules />
      <!--/.Box-flame-->
      <div id="estimate" class="Control-area">
        <button
          type="button"
          id="FormAssist_submit"
          @click="goToConfirm()"
          class="Control-area__btn"
        >
          個人情報保護宣言に同意して<span class="Control-area__btn__em"
            >入力内容の確認へ</span
          >
        </button>
      </div>
    </div>
    <!-- KARTE計測に使用するUserIDの設定 -->
    <input type="hidden" id="karte-user-id" />
  </div>
</template>
<!-- // templatesコンポーネントでは以下のことを行う
// 1. organismsコンポーネントの呼び出し
// 2. 組み合わせによってページのワイヤーフレームを作る
// 3. 基本的にtemplatesコンポーネントにはデータの取得/更新は行わない
// 4. ページレイアウトに及ぶCSSの適用 -->
<script src="./CatalogInput.ts" />

<style scoped>
.l-base {
  width: 1040px;
  margin: 0 auto;
}
.Box-flame {
  overflow: hidden;
  border: 1px solid #bfbfbf;
  border-radius: 8px;
  background: #fafafa;
}
.Form-wrap .Box-flame {
  border-color: #80b735;
  box-shadow: none;
}
.Form-tbl {
  width: 100%;
  background: #f4faeb;
}
table {
  border-collapse: collapse;
}
.Ico-must {
  display: inline-block;
  width: 40px;
  height: 22px;
  border: 2px solid #f00;
  border-radius: 4px;
  background: #fff;
  color: #f00;
  font-size: 1.2rem;
  text-align: center;
  line-height: 18px;
}
.Form-tbl__th,
.Form-tbl__td {
  color: #2c4318;
}
.Input {
  width: 100%;
  min-height: 46px;
  margin: 9px 0;
  padding: 0 18px;
  border: 1px solid #3f5d26;
  border-radius: 4px;
}
button,
input {
  overflow: visible;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
.Select-wrap {
  position: relative;
  margin: 9px 0;
}
.Select-wrap::before {
  width: 44px;
  height: 100%;
  margin-left: -44px;
  border-radius: 0 4px 4px 0;
  background: #3f5d26;
}
.Custom-select {
  width: 100%;
  height: 46px;
  padding: 0 42px 0 18px;
  border-radius: 4px;
  border: 1px solid #3f5d26;
}
button,
select {
  text-transform: none;
}
.Select-wrap::after {
  top: 50%;
  width: 0;
  height: 0;
  margin: -5px 0 0 -32px;
  border-width: 12px 10px 0 10px;
  border-color: #fff transparent transparent transparent;
  border-style: solid;
}
.Select-wrap::before,
.Select-wrap::after {
  position: absolute;
  left: 100%;
  pointer-events: none;
  content: "";
}
.contact-info-table {
  border-collapse: separate;
  border-spacing: 0;
}
.contact-info-table tr:first-child > th.Form-tbl__th--confirm {
  border-right: 1px dotted #ccc;
}
.Input--zip {
  width: 190px;
}
.Btn--type01::before {
  width: 24px;
  height: 24px;
  margin: -12px 0 0 -38px;
}
.Btn::before {
  border-radius: 50%;
  background: #3f5d26;
}
.Btn--type01::after {
  margin: -6px 0 0 -29px;
  border-width: 6px 0 6px 9px;
}

.Btn::after {
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent transparent transparent #f1f3ee;
}
.Btn::before,
.Btn::after {
  display: block;
  position: absolute;
  top: 50%;
  left: 100%;
  content: "";
}
.Address-holder__sub {
  font-size: 1.6rem;
  box-sizing: border-box;
}
.Link-arw {
  display: inline-block;
  padding-left: 11px;
  text-indent: -11px;
  color: #0044cc;
}

.Ex-txt--min {
  font-size: 1.6rem;
}
.Ex-txt {
  color: #666;
}

h1,
h2,
h3,
h4,
h5,
h6,
dl,
dt,
dd {
  margin: 0;
  font-size: 100%;
}
dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}
.Control-area {
  position: relative;
  margin: 104px 0 66px;
  text-align: center;
}
.Control-area::before {
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  width: 0;
  height: 0;
  margin: -73px 0 0 -109px;
  border-width: 46px 109px 0 109px;
  border-color: #ff9900 transparent transparent transparent;
  border-style: solid;
  content: "";
}
.Control-area__btn::before {
  width: 30px;
  height: 30px;
  margin: -15px 0 0 -50px;
  border-radius: 50%;
  background: #fff;
}
.Control-area__btn::after {
  width: 0;
  height: 0;
  margin: -8px 0 0 -38px;
  border-style: solid;
  border-width: 8px 0 8px 11px;
  border-color: transparent transparent transparent #ff9900;
}
.Control-area__btn::before,
.Control-area__btn::after {
  display: block;
  position: absolute;
  top: 50%;
  left: 100%;
  content: "";
}
.Block-head__txt {
  margin-top: 32px;
  margin-bottom: 32px;
  text-align: center;
}
.Form-tbl__th--resi {
  position: relative;
  width: calc(100% - 582px);
  padding: 0 15px 0 26px;
  font-weight: normal;
  vertical-align: middle;
  align-items: center;
  letter-spacing: normal;
}

.Link-arw::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 7px;
  border-style: solid;
  border-width: 4px 0 4px 6px;
  border-color: transparent transparent transparent #0044cc;
  content: "";
}

@media screen and (min-width: 501px) {
  .l-base {
    width: 100%;
    margin: 0 auto;
  }
  .Form-tbl__tr:not(:first-of-type) {
    border-top: 1px dotted #ccc;
    border-top-width: 1px;
    border-top-style: dotted;
    border-top-color: rgb(204, 204, 204);
  }
  .Form-name-pc {
    display: flex;
    padding: 10px 15px 5px 0;
    justify-content: space-between;
    align-items: center;
  }
  .Form-name-sp {
    display: none;
  }
  .policy-sp {
    display: none;
  }
  .Form-wrap {
    width: 840px;
    margin: 0 auto;
  }

  .Box-flame + .Box-flame {
    margin-top: 20px;
  }

  .Form-tbl__th {
    position: relative;
    width: calc(100% - 582px);
    padding: 0 0 0 26px;
    font-weight: normal;
    vertical-align: middle;
    align-items: center;
    letter-spacing: normal;
  }
  .Form-name {
    display: flex;
    padding: 10px 15px 5px 0;
    justify-content: space-between;
    align-items: center;
  }

  .Form-tbl__td {
    padding: 5px 24px;
    word-break: break-all;
    width: 582px;
    border-left: 1px solid #80b735;
    background: #fff;
  }

  .Date-box {
    display: flex;
    max-width: 532px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  .Btn {
    display: flex;
    position: relative;
    border: 2px solid #799b3f;
    border-radius: 8px;
    background: #f1f3ee;
    font-size: 1.8rem;
    color: #3f5d26 !important;
    text-align: center;
    text-decoration: none !important;
    line-height: 1.2;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
  }
  .Btn--type01 {
    width: 322px;
    height: 56px;
    padding: 0 40px 0 10px;
    font-weight: normal;
  }

  .contact-info-table tr:nth-child(n + 2) > th,
  .contact-info-table tr:nth-child(n + 2) > td {
    border-top: 1px dotted #ccc;
  }

  .Control-area__btn {
    position: relative;
    width: 580px;
    min-height: 121px;
    padding: 20px 0 11px;
    border: none;
    border-radius: 8px;
    background: #ff9900;
    box-shadow: 0 0 0 1px rgb(0 16 14 / 3%), 0 8px 16px -4px rgb(0 16 14 / 31%);
    color: #fff;
    font-size: 2.4rem;
    line-height: 1;
    transition: all 0.3s ease;
  }
  .Control-area__btn__em {
    display: block;
    font-size: 4rem;
    font-weight: bold;
    line-height: 1.6;
  }
}

@media screen and (max-width: 500px) {
  .Form-name-pc {
    display: none;
  }
  .Form-name-sp {
    display: flex;
    padding: 10px 15px 5px 0;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
  }
  br {
    display: none;
  }
  .policy-sp {
    padding-top: 20px;
  }
  .policy-open {
    font-size: 2.1rem;
    color: #6c8939;
    padding-bottom: 10px;
    border-bottom: 1px solid #6c8939;
  }
  .policy-close {
    font-size: 2.1rem;
    color: #6c8939;
    padding-bottom: 10px;
  }
  .policy {
    display: none;
  }
  .l-base {
    width: 100%;
    margin: 0 auto;
  }
  .Form-wrap {
    width: 95%;
    margin: 0 auto;
  }

  .Box-flame + .Box-flame {
    margin-top: 20px;
  }

  .Form-tbl__th {
    position: relative;
    width: 100%;
    padding: 0 0 0 10px;
    font-weight: normal;
    vertical-align: middle;
    align-items: center;
    letter-spacing: normal;
  }
  .Form-name {
    display: flex;
    padding: 10px 15px 5px 0;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
  }

  .Form-tbl__td {
    padding: 5px 24px;
    word-break: break-all;
    width: 100%;
    background: #fff;
  }

  .Date-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }

  .Select-wrap::after {
    top: 50%;
    width: 0;
    height: 0;
    margin: -5px 0 0 -32px;
    border-width: 12px 10px 0 10px;
    border-color: #fff transparent transparent transparent;
    border-style: solid;
  }
  .Select-wrap::before,
  .Select-wrap::after {
    position: absolute;
    left: 100%;
    pointer-events: none;
    content: "";
  }
  .contact-info-table {
    border-collapse: separate;
    border-spacing: 0;
  }
  .contact-info-table tr:first-child > th.Form-tbl__th--confirm {
    border-right: 1px dotted #ccc;
  }
  .Form-tbl__th--col {
    padding: 0 0 0 10px;
    line-height: 1.3;
  }
  #addarea1 {
    display: none;
  }

  .Btn {
    display: flex;
    position: relative;
    width: 100%;
    padding: 0 40px 0 20px;
    border: 2px solid #3f5d26;
    border-radius: 8px;
    background: #f4faeb;
    color: #3f5d26 !important;
    text-align: center;
    text-decoration: none !important;
    font-weight: bold;
    line-height: 1.2;
    justify-content: center;
    align-items: center;
  }
  .Btn--type01 {
    height: 56px;
    margin-bottom: 10px;
    font-size: 1.4rem;
    box-shadow: none;
  }

  .Form-tbl__tr {
    display: flex;
    flex-direction: column;
  }
  .contact-info-table tr:nth-child(n + 2) > th,
  .contact-info-table tr:nth-child(n + 2) > td {
    border-top: 1px dotted #ccc;
  }

  .Control-area__btn {
    position: relative;
    width: 100%;
    min-height: 121px;
    padding: 20px 0 11px;
    border: none;
    border-radius: 8px;
    background: #ff9900;
    box-shadow: 0 0 0 1px rgb(0 16 14 / 3%), 0 8px 16px -4px rgb(0 16 14 / 31%);
    color: #fff;
    font-size: 1.7rem;
    line-height: 1;
    transition: all 0.3s ease;
  }

  .Control-area__btn__em {
    display: block;
    font-size: 3.2rem;
    font-weight: bold;
    line-height: 1.6;
  }
}
</style>
